import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Confetti from "react-confetti";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import headingimage from "../../assets/images/party.png";
import catImage from "../../assets/images/Class Reward Chart  (2) (1).png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { tableCellClasses } from "@mui/material/TableCell";
import { createTheme } from "@mui/material/styles";

import { styled } from "@mui/material/styles";
import {
  get_reward_chart_history_api,
  reward_chart_entry_api,
  unselect_reward_chart_entry_api,
} from "src/DAL/rewardChart";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PageLoading from "../pageLoading";
import { s3baseUrl } from "src/config/config";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Spinner } from "react-bootstrap";
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  padding: theme.spacing(2),
  position: "relative",
}));
const FlowerContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  background: `url(${headingimage}) no-repeat center center`, // Using local image
  backgroundSize: "cover",
  opacity: 0.2,
  zIndex: -1,
}));
const FlowerAnimation = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "60%",
  width: "100px",
  height: "100px",
  background: `url(${headingimage}) no-repeat center center`,
  backgroundSize: "contain",
  animation: "floating 5s infinite ease-in-out",
  zIndex: 1,
  "@keyframes floating": {
    "70%": { transform: "translateY(0)" },
    "70%": { transform: "translateY(-15px)" },
    "100%": { transform: "translateY(0)" },
  },
}));
const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "gray",
            cursor: "not=allowed",
          },
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gold !important",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const DayTableCell = styled(TableCell)(({ rowIndex }) => ({
  border: "1px solid black",
  textAlign: "center",
  height: "200px",
  width: "170px",
  backgroundColor: rowIndex === 0 || rowIndex === 4 ? "red" : "gold",
}));
const CustomArrowForwardIcon = styled(ArrowForwardIcon)(
  ({ theme, disabled }) => ({
    color: disabled ? "#808080 !important" : "#9f00ff",
    cursor: disabled ? "not-allowed" : "pointer",
  })
);
const CustomArrowBackIcon = styled(ArrowBackIcon)(({ theme, disabled }) => ({
  color: disabled ? "#808080 !important" : "#9f00ff",
  cursor: disabled ? "not-allowed !important" : "pointer",
}));

export default function RewardChart({ oopen }) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleGeneralSettingData } = useContentSetting();
  const [history, setHistory] = useState([]);

  const [weekOffset, setWeekOffset] = useState(0);
  const [starStates, setStarStates] = useState({});
  const [charImage, setCharImage] = useState(null);
  const [weekDates, setWeekDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstDate, setFirstDate] = useState(null);
  const [hasNavigatedBack, setHasNavigatedBack] = useState(false);
  const [isBackDisabled, setIsBackDisabled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [is_bonus_achieved, set_Is_bonus_achieved] = useState();
  const [isLoadingBack, setIsLoadingBack] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const tableRef = useRef(null);

  const [showCongratulations, setShowCongratulations] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    gratitude: false,
    affirmation: false,
    meditation: false,
    attitude: false,
  });

  const [fireworks, setFireworks] = useState([]);
  const [showFireworks, setShowFireworks] = useState(true);
  const [open, setOpen] = useState(true);

  const [flowers, setFlowers] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
    setShowCongratulations(false);
  };

  const handleClose = () => {
    setOpen(false);
    setShowCongratulations(true);
  };

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        setFlowers((prev) => [
          ...prev,
          {
            id: Date.now(),
            top: Math.random() * 100 + "%",
            left: Math.random() * 100 + "%",
          },
        ]);
      }, 500);

      const timer = setTimeout(() => {
        clearInterval(interval);
      }, 20000);

      return () => {
        clearInterval(interval);
        clearTimeout(timer);
        setFlowers([]);
      };
    }
  }, [open]);

  const today = moment().add(weekOffset, "weeks");
  const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const checkFirstDateInCurrentWeek = () => {
    const startOfWeek = today.clone().startOf("isoWeek");
    const endOfWeek = startOfWeek.clone().endOf("isoWeek").subtract(2, "days");

    if (
      firstDate &&
      moment(firstDate).isSameOrAfter(startOfWeek) &&
      moment(firstDate).isSameOrBefore(endOfWeek)
    ) {
      setIsBackDisabled(true);
    } else {
      setIsBackDisabled(false);
    }
  };
  useEffect(() => {
    checkFirstDateInCurrentWeek();
  }, [firstDate, weekOffset]);
  const fetchRewardChartHistoryBonus = async () => {
    // setIsLoading(true);

    const startOfWeek = today.clone().startOf("isoWeek");
    const endOfWeek = startOfWeek.clone().endOf("isoWeek").subtract(2, "days");

    try {
      const response = await get_reward_chart_history_api({
        start_date: startOfWeek.format("YYYY-MM-DD"),
        end_date: endOfWeek.format("YYYY-MM-DD"),
      });
      console.log(response, "responseresponseresponseresponse");

      if (response.code === 200) {
        set_Is_bonus_achieved(response?.is_bonus_achieved);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(
        "Error fetching history. Please try again Might be Internet Issue.",
        {
          variant: "error ",
        }
      );
    } finally {
    }
  };
  useEffect(() => {
    fetchRewardChartHistoryBonus();
  }, []);
  const fetchRewardChartHistory = async () => {
    setIsLoading(true);

    const startOfWeek = today.clone().startOf("isoWeek");
    const endOfWeek = startOfWeek.clone().endOf("isoWeek").subtract(2, "days");

    try {
      const response = await get_reward_chart_history_api({
        start_date: startOfWeek.format("YYYY-MM-DD"),
        end_date: endOfWeek.format("YYYY-MM-DD"),
      });

      if (response.code === 200) {
        for (let i = 0; i < weekDates.length; i++) {
          for (let j = 0; j < response.data.length; j++) {
            const weekDate = new Date(weekDates[i].date);
            const responseDate = new Date(response.data[j].date);

            if (
              weekDate.getFullYear() === responseDate.getFullYear() &&
              weekDate.getMonth() === responseDate.getMonth() &&
              weekDate.getDate() === responseDate.getDate()
            ) {
              weekDates[i].statuArray[0].status = response.data[j].gratitude;
              weekDates[i].statuArray[1].status = response.data[j].meditation;
              weekDates[i].statuArray[2].status = response.data[j].affirmation;
              weekDates[i].statuArray[3].status = response.data[j].attitude;
            }
          }
        }

        setWeekDates([...weekDates]);
        setHistory(response.data);
        setFirstDate(response.first_saved_date || null);
        // set_Is_bonus_achieved(response?.is_bonus_achieved);

        const initialStarStates = {};
        setStarStates(initialStarStates);
        setCharImage(response?.chart_image);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(
        "Error fetching history. Please try again Might be Internet Issue.",
        {
          variant: "error ",
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardChartHistory();
  }, [weekOffset]);

  useEffect(() => {
    for (let i = 1; i <= 5; i++) {
      const date = today.clone().isoWeekday(i);
      weekDates.push({
        day: dayNames[i - 1],
        date: date.format("YYYY-MM-DD"),
        fullDate: date.format("YYYY-MM-DD"),
        statuArray: [
          { status: false, type: "gratitude", isDisable: false },
          { status: false, type: "meditation", isDisable: false },
          { status: false, type: "affirmation", isDisable: false },
          { status: false, type: "attitude", isDisable: false },
        ],
      });
    }
  }, []);
  const handleCheckPast = (rowIndex, statusItem, item) => {
    return (
      moment(weekDates[rowIndex].fullDate)
        .startOf("day")
        .isBefore(today, "day") && statusItem.status == true
    );
  };
  const handleCheckPastWeek = (past, status) => {
    if (past && status.status == true) {
      return true;
    }
  };
  const handleLastCellClick = (status) => {
    // const allTasksCompleted = weekDates.every((date) =>
    //   date.statuArray.every((task) => task.status)
    // );
  };
  const handleCellClick = async (rowIndex, colIndex, status) => {
    if (is_bonus_achieved) {
      // enqueueSnackbar("jeet gye hain ap ", { variant: "error" });
      return;
    }

    if (status.status == true) {
      weekDates[rowIndex].statuArray[colIndex].status = false;
    } else {
      weekDates[rowIndex].statuArray[colIndex].status = true;
    }
    weekDates[rowIndex].statuArray[colIndex].isDisable = true;

    setWeekDates([...weekDates]);

    const cellDate = weekDates[rowIndex].fullDate;
    const isToday = moment(cellDate).isSame(moment(), "day");
    const isFutureDate = moment(cellDate).isAfter(moment());
    const isPastDate = moment(cellDate).isBefore(today, "day");
    const types = ["gratitude", "meditation", "affirmation", "attitude"];
    const selectedType = status.type;

    if (!isFutureDate || isToday) {
      const currentStarState = starStates[cellDate] || [];
      const isUnselecting = currentStarState.includes(colIndex);
      const allTasksCompleted = weekDates.every((date) =>
        date.statuArray.every((task) => task.status)
      );

      // if (isUnselecting && !isToday) return;

      if (status.status) {
        let capitalizedType =
          status.type.charAt(0).toUpperCase() + status.type.slice(1);

        if (status.type.toLowerCase() === "attitude") {
          capitalizedType = "Good " + capitalizedType;
        }

        enqueueSnackbar(
          `${capitalizedType} marked as completed successfully!`,
          {
            variant: "success",
          }
        );
      }

      try {
        const postData = { date: cellDate, activity_type: status.type };
        const result = status.status
          ? await reward_chart_entry_api(postData)
          : await unselect_reward_chart_entry_api(postData);
        console.log(result, "resultresultresultresultresult");

        if (result.code === 200) {
          // enqueueSnackbar(result.message, { variant: "success" });
          weekDates[rowIndex].statuArray[colIndex].isDisable = false;
          setWeekDates([...weekDates]);
          // set_Is_bonus_achieved(result?.is_bonus_achieved);

          handleGeneralSettingData(result?.reward_completion_coins);

          if (result.bonus_achieved) {
            setShowCongratulations(true);
          }
        } else {
          console.log(result.message, "Ssssssss");
        }
        fetchRewardChartHistoryBonus();
      } catch (error) {
        enqueueSnackbar("Error occurred while processing star selection", {
          variant: "error",
        });
      } finally {
        setLoadingStates((prev) => ({
          ...prev,
          [selectedType]: false,
        }));
      }
    }
  };

  const handleNextClick = () => {
    setIsLoadingNext(true);

    const nextWeekStart = today.clone().startOf("isoWeek").add(7, "days");

    const filteredNextWeekDates = [];
    for (let i = 1; i <= 5; i++) {
      const date = nextWeekStart.clone().isoWeekday(i);
      filteredNextWeekDates.push({
        day: dayNames[i - 1],
        date: date.format("YYYY-MM-DD"),
        fullDate: date.format("YYYY-MM-DD"),
        statuArray: [
          { status: false, type: "gratitude", isDisable: false },
          { status: false, type: "meditation", isDisable: false },
          { status: false, type: "affirmation", isDisable: false },
          { status: false, type: "attitude", isDisable: false },
        ],
      });
    }

    setWeekDates(filteredNextWeekDates);
    setWeekOffset((prev) => prev + 1);
  };

  const handleBackClick = () => {
    if (isBackDisabled) return;
    setIsLoadingBack(true);
    const prevWeekStart = today.clone().startOf("isoWeek").subtract(7, "days");
    const prevWeekEnd = prevWeekStart
      .clone()
      .endOf("isoWeek")
      .subtract(2, "days");

    const filteredWeekDates = [];
    for (let i = 1; i <= 5; i++) {
      const date = prevWeekStart.clone().isoWeekday(i);
      if (date.isBefore(moment())) {
        filteredWeekDates.push({
          day: dayNames[i - 1],
          date: date.format("YYYY-MM-DD"),
          fullDate: date.format("YYYY-MM-DD"),
          statuArray: [
            { status: false, type: "gratitude", isDisable: false },
            { status: false, type: "meditation", isDisable: false },
            { status: false, type: "affirmation", isDisable: false },
            { status: false, type: "attitude", isDisable: false },
          ],
        });
      }
    }

    setWeekDates(filteredWeekDates);
    setHasNavigatedBack(true);

    setWeekOffset((prev) => prev - 1);
  };
  const montserratBold = "data:font/ttf;base64,..."; // Add your base64 font data here

  // const downloadTableAsPDF = async () => {
  //   if (!tableRef.current) {
  //     console.error("Table reference is not valid or not attached.");
  //     return;
  //   }
  //   setLoading(true);

  //   try {
  //     const canvas = await html2canvas(tableRef.current, { scale: 1 });
  //     const imgData = canvas.toDataURL("image/png");

  //     // Get canvas dimensions
  //     const canvasWidth = canvas.width;
  //     const canvasHeight = canvas.height;

  //     // Create jsPDF with a fixed format
  //     const pdf = new jsPDF({
  //       orientation: "landscape",
  //       unit: "px",
  //       format: [canvasWidth, canvasHeight],
  //     });

  //     pdf.addFileToVFS("Montserrat-Bold.ttf", montserratBold);
  //     pdf.setFont("helvetica", "bold");
  //     pdf.setFontSize(62);

  //     const text = "Class Reward Chart";
  //     const textWidth = pdf.getTextWidth(text);
  //     const x = (canvasWidth - textWidth) / 2;
  //     pdf.setTextColor("black");
  //     pdf.text(text, x, 40);

  //     pdf.setFontSize(16);
  //     pdf.addImage(imgData, "PNG", 0, 60, canvasWidth, canvasHeight);

  //     pdf.save("reward-chart.pdf");
  //   } catch (error) {
  //     console.error("Error generating PDF: ", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const downloadTableAsPDF = async () => {
    if (!tableRef.current) {
      console.error("Table reference is not valid or not attached.");
      return;
    }
    setLoading(true);

    try {
      const canvas = await html2canvas(tableRef.current, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4", // Use standard A4 size
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addFileToVFS("Montserrat-Bold.ttf", montserratBold);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(22);

      const text = "Class Reward Chart";
      const textWidth = pdf.getTextWidth(text);
      const x = (pdfWidth - textWidth) / 2;
      pdf.setTextColor("black");
      pdf.text(text, x, 40);

      // Calculate margins and fit image within A4 size
      const imageWidth = pdfWidth - 40; // Add side margins
      const imageHeight = (canvas.height * imageWidth) / canvas.width; // Maintain aspect ratio
      const yPosition = 60;

      // Center image vertically if it’s shorter than the page
      const centeredYPosition = (pdfHeight - imageHeight) / 2;

      pdf.addImage(
        imgData,
        "PNG",
        20,
        centeredYPosition > yPosition ? centeredYPosition : yPosition,
        imageWidth,
        imageHeight
      );

      pdf.save("reward-chart.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = () => {
    if (charImage) {
      const link = document.createElement("a");
      link.href = s3baseUrl + charImage;
      link.download = charImage;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      enqueueSnackbar("No image available to download", { variant: "error" });
    }
  };
  const checkWeekCompletion = () => {
    const allTasksCompleted = weekDates.every((date) =>
      date.statuArray.every((task) => task.status)
    );

    if (allTasksCompleted) {
      setShowCongratulations(true);
    }
  };

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <div className="container ">
      {showCongratulations && (
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          sx={{}}
          PaperProps={{
            sx: {
              borderRadius: "15px",
              minWidth: "800px",
              minHeight: "300px",
              position: "relative",
              overflow: "hidden",
              backgroundColor: "pink",
            },
          }}
        >
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={500}
          />

          <img
            src={headingimage}
            alt="Kids Sticker"
            style={{
              display: "block",
              margin: "0 auto",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              zIndex: 1,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
            onClick={handleClose}
          >
            <GridCloseIcon />
          </IconButton>

          <StyledDialogTitle>
            <h1 style={{ color: "#9f00ff", fontWeight: "bold" }} variant="h5">
              Congratulations!
            </h1>
          </StyledDialogTitle>

          <DialogContent>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "19px",
              }}
              variant="body1"
            >
              You’ve successfully completed this week’s activities! As a reward,
              you’ve earned Bonus Points, Keep shining !
            </p>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#9f00ff",
                borderRadius: "20px",
                marginBottom: "30px",
                width: "100px",
                "&:hover": {
                  backgroundColor: "#9f00ff",
                  color: "#fff",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>

          {flowers.map((flower) => (
            <div
              key={flower.id}
              className="flower"
              style={{
                position: "absolute",
                top: flower.top,
                left: flower.left,
                width: "30px",
                height: "30px",
                animationDuration: "1s",
              }}
            />
          ))}
        </Dialog>
      )}
      <div className="row">
        <div className="card mt-2">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1
              style={{ color: "#9f00ff", fontWeight: "bold" }}
              className="mt-3"
            >
              Class Reward Chart
            </h1>
            <img
              className="mb-0 mt-0"
              style={{ width: "150px", height: "150px" }}
              src={catImage}
              alt="reward chart image"
            />
          </div>
          <div className="d-flex p-2 justify-content-between">
            <div>
              {!isBackDisabled && firstDate != null ? (
                <Tooltip title="Previous Week" arrow>
                  <span
                    style={{
                      cursor:
                        isBackDisabled || firstDate == null
                          ? "not-allowed"
                          : "pointer",
                      pointerEvents:
                        isBackDisabled || firstDate == null ? "none" : "auto",
                    }}
                  >
                    <IconButton
                      onClick={handleBackClick}
                      disabled={isBackDisabled || firstDate == null}
                      sx={{
                        color:
                          isBackDisabled || weekOffset === 0
                            ? "gray"
                            : "#9f00ff",
                        cursor:
                          isBackDisabled ||
                          firstDate == null ||
                          weekOffset === 0
                            ? "not-allowed"
                            : "pointer",
                        "&.Mui-disabled": {
                          pointerEvents: "fill",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      <CustomArrowBackIcon
                        disabled={isBackDisabled || firstDate == null}
                        // style={{
                        //   color: isBackDisabled ? "gray" : "#9f00ff",
                        //   cursor:
                        //     isBackDisabled || firstDate == null
                        //       ? "not-allowed"
                        //       : "pointer",
                        // }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <span>
                  <IconButton
                    onClick={handleBackClick}
                    disabled={isBackDisabled || firstDate == null}
                    sx={{
                      color:
                        isBackDisabled || weekOffset === 0 ? "gray" : "#9f00ff",
                      cursor: "not-allowed",
                      "&.Mui-disabled": {
                        pointerEvents: "fill",
                        cursor: "not-allowed",
                      },
                      "&:hover": {
                        color: weekOffset === 0 ? "gray" : "#d580ff",
                        backgroundColor: "inherit",
                      },
                    }}
                  >
                    <CustomArrowBackIcon
                      disabled={isBackDisabled || firstDate == null}
                      // style={{
                      //   color: isBackDisabled ? "gray" : "#9f00ff",
                      //   cursor:
                      //     isBackDisabled || firstDate == null
                      //       ? "not-allowed"
                      //       : "pointer",
                      // }}
                    />
                  </IconButton>
                </span>
              )}
              {weekOffset !== 0 ? (
                <Tooltip
                  title="Next Week"
                  arrow
                  style={{
                    cursor: weekOffset == 0 ? "not-allowed" : "pointer",
                  }}
                >
                  <span
                    style={{
                      cursor:
                        weekOffset == 0 ? "not-allowed !important" : "pointer",
                    }}
                  >
                    <IconButton
                      onClick={handleNextClick}
                      disabled={weekOffset === 0}
                      sx={{
                        color: weekOffset === 0 ? "gray" : "#9f00ff",
                        cursor: "not-allowed",
                        "&.Mui-disabled": {
                          pointerEvents: "fill",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      <CustomArrowForwardIcon
                        disabled={weekOffset === 0}
                        style={{
                          cursor:
                            weekOffset == 0
                              ? "not-allowed !important"
                              : "pointer",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <span>
                  <IconButton
                    onClick={handleNextClick}
                    disabled={weekOffset === 0}
                    sx={{
                      color: weekOffset === 0 ? "gray" : "#9f00ff",
                      cursor: "not-allowed",
                      "&.Mui-disabled": {
                        pointerEvents: "fill",
                        cursor: "not-allowed",
                      },
                      "&:hover": {
                        color: weekOffset === 0 ? "gray" : "#d580ff",
                        backgroundColor: "inherit",
                      },
                    }}
                  >
                    <CustomArrowForwardIcon
                      disabled={weekOffset === 0}
                      style={{
                        cursor:
                          weekOffset == 0
                            ? "not-allowed !important"
                            : "pointer",
                      }}
                    />
                  </IconButton>
                </span>
              )}

              <div
                className="d-flex justify-content-end"
                style={{ marginTop: "10px" }}
              ></div>
            </div>
            <div></div>
            <div className="d-flex justify-content-end ">
              <button
                variant="outlined"
                onClick={downloadTableAsPDF}
                disabled={loading} // Disable the button when loading
                style={{ marginRight: "10px", position: "relative" }}
                className="small-outlined-button"
              >
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* Display custom text with a custom spinner (e.g., using react-bootstrap's Spinner) */}
                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                      Downloading...
                    </Typography>
                    <Spinner
                      style={{ color: "#9b41d2" }}
                      animation="border"
                      size="sm"
                    />
                  </Box>
                ) : (
                  <>
                    Download
                    <DownloadIcon sx={{ marginLeft: "8px" }} />
                  </>
                )}
              </button>
              {/* <Tooltip title="Download Reward Chart Image">
                <button
                  className="small-outlined-button"
                  onClick={handleDownloadClick}
                >
                  Download
                  <DownloadIcon sx={{ marginLeft: "8px" }} />
                </button>
              </Tooltip> */}
            </div>
          </div>
          <div ref={tableRef}>
            <TableContainer className="mb-4">
              <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "gold" }}>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    ></StyledTableCell>
                    {[
                      "Gratitude",
                      "Meditation",
                      "Affirmations",
                      "Good Attitude",
                    ].map((header) => (
                      <StyledTableCell
                        key={header}
                        sx={{
                          border: "1px solid black",
                          textAlign: "center",
                          backgroundColor: "gold",
                          fontWeight: "bold",
                          width: "150px",
                        }}
                      >
                        {header}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weekDates.map((item, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <DayTableCell
                        sx={{
                          border: "1px solid black",
                          textAlign: "center",
                          height: "200px",
                          backgroundColor:
                            rowIndex === 0 || rowIndex === 4 ? "red" : "gold",
                          "&.MuiTableCell-root": {
                            backgroundColor: "gold !important",
                            fontWeight: "bold",
                            width: "150px",
                          },
                        }}
                      >
                        {item.day} <br />{" "}
                        {moment(item.date).format("DD/MM/YYYY")}{" "}
                      </DayTableCell>
                      {item?.statuArray?.map((statusItem, colIndex) => {
                        const isFutureDate = moment(item.date).isAfter(
                          moment()
                        );

                        const isPastWeek = moment(item.date).isBefore(
                          moment().startOf("isoWeek")
                        );
                        return (
                          <TableCell
                            key={colIndex}
                            onClick={
                              !statusItem.isDisable
                                ? () =>
                                    !isFutureDate &&
                                    !isPastWeek &&
                                    !handleCheckPast(rowIndex, statusItem) &&
                                    handleCellClick(
                                      rowIndex,
                                      colIndex,
                                      statusItem
                                    )
                                : null
                            }
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              height: "200px",

                              cursor:
                                isFutureDate ||
                                isPastWeek ||
                                statusItem.isDisable
                                  ? "not-allowed"
                                  : "pointer", //
                              backgroundColor:
                                isFutureDate || isPastWeek
                                  ? "#f0f0f0"
                                  : "inherit",
                            }}
                          >
                            <div>
                              {(starStates[item.fullDate]?.includes(colIndex) ||
                                statusItem?.status === true) && (
                                <Tooltip
                                  title={
                                    is_bonus_achieved && !isPastWeek
                                      ? "You've already earned your bonus points for this week, so your reward chart can't be updated now"
                                      : handleCheckPast(
                                          rowIndex,
                                          statusItem,
                                          item
                                        ) ||
                                        handleCheckPastWeek(
                                          isPastWeek,
                                          statusItem
                                        )
                                      ? "Past   activities can't be marked incomplete."
                                      : ""
                                  }
                                >
                                  <IconButton
                                    sx={{ padding: 0 }}
                                    disabled={statusItem.isDisable}
                                  >
                                    <StarIcon
                                      sx={{
                                        color: "gold",
                                        height: "70px",
                                        width: "70px",
                                        cursor: statusItem.isDisable
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
