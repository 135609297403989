import { invokeApi } from "src/bl_libs/invokeApi";

export const reward_chart_entry_api = async (data) => {
  const requestObj = {
    path: `api/member/make_reward_chart_entry`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const unselect_reward_chart_entry_api = async (data) => {
  const requestObj = {
    path: `api/member/delete_reward_chart_entry`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_reward_chart_history_api = async (startDate, endDate) => {
  console.log(startDate, "api datesssss");
  const requestObj = {
    path: `api/member/get_reward_chart_entry?start_date=${startDate.start_date}&end_date=${startDate.end_date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
