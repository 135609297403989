import { CircularProgress } from "@mui/material";
import React from "react";

export default function PageLoading() {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="member-loader-div">
        <CircularProgress style={{ color: "#EA7423" }} />
      </div>
    </div>
  );
}
